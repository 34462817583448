/* eslint-disable react/no-unescaped-entities */
import { ReactElement, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spin } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import useSWR from 'swr';

import { getSpecById } from '1_shared/api/specialistById/specService';
import { timeConfig } from '1_shared/config/timeConfig';
import { uzsConvert } from '1_shared/lib/helpers/uzsConvert';
import { Breadcrumbs, Button, Input, Typography } from '1_shared/ui';
import { SpecialistAvatar } from '2_entities';

import { DurationType } from '../../../1_shared/config/interfaces/DurationType';
import { ISlot } from '../../../1_shared/config/interfaces/ISlot';
import { ISpecialistShortData } from '../../../1_shared/config/interfaces/ISpecialistShortData';
import { RoutePath } from '../../../1_shared/config/routes';
import { DateSelect } from '../../../3_features/DateSelect';
import PhoneOrderInput from '../../../3_features/PhoneOrderInput/ui/PhoneOrderInput';
import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import { configMedia } from '../../SpecialistCard/ui/config/configMedia';
import { schema } from '../config/schema';
import usePayment from '../model/usePayment';

import { IApplicationForm } from './interface/IApplicationForm';

import styles from './OrderForm.module.scss';

const breadCrumbs = [
  {
    title: (
      <Link to={RoutePath.MAIN}>
        <Typography type="description">PSYCHOLOGI</Typography>
      </Link>
    ),
  },
  {
    title: (
      <Link to={RoutePath.SPECIALISTS}>
        <Typography type="description">СПЕЦИАЛИСТЫ ДЛЯ МЕНЯ</Typography>
      </Link>
    ),
  },
];

const OrderForm = (): ReactElement => {
  const { state } = useLocation();
  const [isClickButtonOrder, setIsClickButtonOrder] = useState<boolean>(false);

  const [showTelegramLink, setShowTelegramLink] = useState<boolean>(false);

  const { data: spec, isLoading } = useSWR(
    `/spec/specialist/${(state as any)?.id}`,
    getSpecById,
  );

  const {
    payment,
    onPromoCodeFind,
    promoCode,
    isPromoLoading,
    errorPromo,
    setPromoCode,
  } = usePayment();

  const { user } = useAuthContext();
  const methods = useForm<IApplicationForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      slotId:
        (state as any)?.slot?.slotId ?? spec?.slots[0]?.slotId ?? undefined,
    },
  });

  const { control, handleSubmit, watch, getValues } = methods;
  watch(['slotId', 'phone', 'nickname', 'email', 'promoCode']);

  const slot = useMemo(
    () =>
      spec?.slots?.find(slot =>
        getValues('slotId')
          ? slot.slotId === getValues('slotId')
          : (slot.slotId === (state as any)?.slot?.slotId ??
            spec?.slots[0]?.slotId),
      ),
    [getValues('slotId'), spec?.slots, state],
  );

  const onSubmit = async (data: IApplicationForm) => {
    setIsClickButtonOrder(!isClickButtonOrder);
    await payment(
      // TODO: нужно брать цену слота по типу сессии
      Number(spec?.sessionsInfo[0].price ?? 0),
      {
        ...data,
        time: dayjs(data.time).toISOString(),
        fullSpecName: `${spec?.firstName} ${spec?.secondName}`,
      },
      spec as ISpecialistShortData,
      slot as ISlot,
    );
    setShowTelegramLink(true);
    window.open('https://t.me/TestUZClientBot', '_blank');
  };

  const calculatedPrice = useMemo(() => {
    const price = spec?.sessionsInfo[0].price ?? 0;
    return price && promoCode ? price - promoCode.discount : price;
  }, [promoCode, spec]);

  return (
    <div className={styles.root}>
      <div>
        <Breadcrumbs items={breadCrumbs} />
        <Typography type="h2">Запись на прием</Typography>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.formWrapper}>
          <div onSubmit={handleSubmit(onSubmit)} className={styles.formGrid}>
            <div className={styles.row}>
              <Typography type="subtitle" className={styles.rowText}>
                СПЕЦИАЛИСТ
              </Typography>
              {isLoading ? (
                <Spin />
              ) : (
                <SpecialistAvatar
                  firstName={String(spec?.firstName)}
                  lastName={String(spec?.secondName)}
                  imgPreview={String(
                    spec?.mediaContentResponse[0]?.mediaContentResponse
                      .previewLink ??
                      configMedia.mediaContentResponse.previewLink,
                  )}
                />
              )}
            </div>
            <Controller
              control={control}
              name="slotId"
              render={({ field }) => {
                const duration = spec?.slots.find(
                  el => el.slotId === field.value,
                )?.duration;
                return (
                  <div className={styles.row}>
                    <Typography type="subtitle" className={styles.rowText}>
                      ДАТА И ВРЕМЯ
                    </Typography>

                    <DateSelect name="time" />
                    {duration && (
                      <Typography type="description">
                        {`Длительность сеанса ${timeConfig[duration as DurationType]}`}
                      </Typography>
                    )}
                  </div>
                );
              }}
            />
            <Controller
              control={control}
              name="nickname"
              render={({ field, fieldState }) => (
                <div className={styles.row}>
                  <Typography type="subtitle" className={styles.rowText}>
                    ИМЯ ИЛИ ПСЕВДОНИМ
                  </Typography>
                  <Input
                    className={cn({ [styles.activeInput]: !!field?.value })}
                    size="middle"
                    status={fieldState.error ? 'error' : ''}
                    {...field}
                  />
                  <Typography type="description">
                    Можете указать псевдоним, если не хотите раскрывать имя.
                    Ваши данные строго конфиденциальны
                  </Typography>
                </div>
              )}
            />
            <Controller
              control={control}
              name="phone"
              render={({ field: { value, onChange } }) => (
                <PhoneOrderInput value={value} onChange={onChange} />
              )}
            />
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState }) => (
                <div className={styles.row}>
                  <Typography type="subtitle" className={styles.rowText}>
                    E-MAIL
                  </Typography>
                  <Input
                    className={cn({ [styles.activeInput]: !!field?.value })}
                    size="middle"
                    status={fieldState.error ? 'error' : ''}
                    {...field}
                  />
                  <Typography type="description">
                    Необязательное поле для заполнения
                  </Typography>
                </div>
              )}
            />
            <div className={styles.row}>
              <Typography type="subtitle" className={styles.rowText}>
                ПРОМОКОД
              </Typography>
              <Controller
                control={control}
                name="promoCode"
                render={({ field }) => (
                  <div className={styles.columnBtn}>
                    <Input
                      size="middle"
                      disabled={!!promoCode} //  || !user
                      {...field}
                      suffix={
                        !promoCode ? (
                          <Button
                            type="text"
                            disabled={!field.value || !getValues('slotId')} // || !user
                            onClick={() =>
                              onPromoCodeFind({
                                name: String(field.value),
                                slotId: getValues('slotId'),
                                clientId: user?.id as string,
                              })
                            }
                          >
                            <Typography
                              className={styles.codeBtn}
                              type="description"
                            >
                              {!isPromoLoading ? `ПРИМЕНИТЬ` : <Spin />}
                            </Typography>
                          </Button>
                        ) : (
                          <Button
                            type="text"
                            disabled={
                              !field.value || !getValues('slotId') || !user
                            }
                            onClick={() => {
                              setPromoCode(undefined);
                              field.onChange('');
                            }}
                          >
                            <Typography
                              className={styles.codeBtn}
                              type="description"
                            >
                              ИЗМЕНИТЬ
                            </Typography>
                          </Button>
                        )
                      }
                    />
                    <Typography type="description" className={styles.error}>
                      {errorPromo}
                    </Typography>
                  </div>
                )}
              />
              {promoCode ? (
                <Typography type="description">
                  Ваша скидка - {promoCode.discount}
                </Typography>
              ) : (
                <Typography type="description">
                  Код из подарочного сертификата тоже сюда
                </Typography>
              )}
            </div>
          </div>
          {isClickButtonOrder ? (
            <div className={styles.completeOrder}>
              <Typography type="textM">
                Заявка на сеанс успешно принята! В ближайшее время с вами
                свяжется менеджер.
              </Typography>
              {showTelegramLink && (
                <>
                  <Typography type="textM">
                    Если этого не произошло, нажмите на кнопку "Перейти в
                    Телеграмм"!
                  </Typography>
                  <div>
                    <a
                      href="https://t.me/TestUZClientBot"
                      target="_blank"
                      className="btn btn--violet"
                      type="primary"
                      rel="noreferrer"
                    >
                      ПЕРЕЙТИ В ТЕЛЕГРАММ
                    </a>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className={styles.btnWrapper}>
              <Typography type="subtitle" className={styles.rowText}>
                ИТОГО
              </Typography>
              <div className={styles.costWrapper}>
                <Typography type="title" className={styles.rowText}>
                  {uzsConvert(calculatedPrice)}
                </Typography>
                <Button
                  htmlType="submit"
                  className={styles.btn}
                  disabled={!methods.formState.isValid}
                >
                  ОФОРМИТЬ ЗАЯВКУ
                </Button>
              </div>
              <Typography type="description">
                После подтверждения мы пришлем
                <br />
                вам доступ на платформу
              </Typography>
            </div>
          )}
        </form>
      </FormProvider>
    </div>
  );
};

export default OrderForm;
